<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="手术室"/>
        <div style="margin-top: 20px">
            <div class="search">
                <el-input v-model="keywords" size="small" style="width: 20%"></el-input>
                <el-button type="primary" plain size="small" style="margin-left:10px ">检索</el-button>
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleAdd">添加手术室</el-button>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="index" width="50" align="center"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)"
                                   size="small"
                                   type="warning"
                                   icon="el-icon-edit">
                        </el-button>
                        <el-button @click="handleDel(scope.row)"
                                   size="small"
                                   type="danger"
                                   icon="el-icon-delete"
                        >
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div style="text-align: center;margin: 20px" v-if="table.total > table.pageSize">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize"
                               @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
        <el-dialog
            title="手术室"
            :visible.sync="dialogVisible"
            width="40%"
            center
        >
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="院区">
                    <el-select v-model="form.area" placeholder="请选择">
                        <el-option v-for="item in list_area" :key="item.name" :label="item.name" :value="item.name"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="手术室名称">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="位置">
                    <el-input v-model="form.location" style="width:80%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                loading: false,
                table: {
                    columns: [
                        {title: '院区', field: 'area', width: ''},
                        {title: '手术室名称', field: 'name', width: ''},
                        {title: '位置', field: 'location', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                list_area: [],
                keywords: '',
                dialogVisible: false,
                form: {
                    name: '',
                    location: '',
                },
                rules: {
                    doctor: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.form = {name: '', location: ''}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.dialogVisible = true
                this.form = row
            },
            async handleDel(row){
                const resp = await this.$http.delete(`/surgery/theater/${row.ID}`)
                console.log(resp)
                this.$message.success("删除成功")
                this.getData()
            },
            async addSubmit() {
                let resp = null
                if (this.form.ID) {
                    resp = await this.$http.put(`/surgery/theater/${this.form.ID}`, this.form)
                } else {
                    resp = await this.$http.post('/surgery/theater/', this.form)
                }
                if (resp.data.code == 200) {
                    this.$message.success("保存成功")
                    this.dialogVisible = false
                } else {
                    this.$message.error(resp.data.msg)
                }
                this.getData()
            },
            async getData() {
                const resp1 = await this.$http.get('/surgery/area/')
                this.list_area = resp1.data.data
                const resp2 = await this.$http.get('/surgery/theater/')
                this.table.data = resp2.data.data
            },
            handleSearch(e) {
                console.log(e)
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        padding-bottom: 40px;
        text-align: center;
    }

</style>
